import axios from 'axios'
import router from "@/router";

const request = axios.create({
    //baseURL: 'http://localhost:80/zhihuiyun',
    baseURL: 'https://hddiancan.cloud/zhihuiyun',
    timeout: 5000
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
// request.interceptors.request.use(config => {
//     config.headers['Content-Type'] = 'application/json;charset=utf-8';
//
//     config.headers['token'] = user.token;  // 设置请求头
//     let userJson = sessionStorage.getItem("user")
//     if(!userJson){
//        router.push("/login")
//     }
//
//     return config
//
// }, error => {
//     return Promise.reject(error)
// });



request.interceptors.request.use(config => {
    // 从 sessionStorage 获取用户信息
    let userJson = sessionStorage.getItem("user");
    if (!userJson) {
        if (!["/login", "/success", "/dangerTemp", "/exam", "/SSP"].includes(router.currentRoute.path)) {
            router.push("/login").catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    console.error('导航错误:', err);
                }
            });
        }
    } else {
        // 解析用户信息并设置 token
        let user = JSON.parse(userJson);
        config.headers['token'] = user.token;
    }

    // 设置 Content-Type
    if (!config.headers['Content-Type'] && !(config.data instanceof FormData)) {
        config.headers['Content-Type'] = 'application/json;charset=utf-8';
    }

    return config;
}, error => {
    return Promise.reject(error);
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        return res;
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)


export default request

