import { render, staticRenderFns } from "./Aside.vue?vue&type=template&id=7c99187e&scoped=true"
import script from "./Aside.vue?vue&type=script&lang=js"
export * from "./Aside.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c99187e",
  null
  
)

export default component.exports