import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        currentUser: (() => {
            const userString = localStorage.getItem('currentUser');
            return userString ? JSON.parse(userString) : null; // 检查空值
        })(),
    },
    mutations: {
        setCurrentUser(state, username) {
            console.log('Setting current user:', username); // 打印日志
            const user = state.currentUser;
            if (user) {
                state.currentUser = user;
                localStorage.setItem('currentUser', JSON.stringify(user));
                console.log('User set successfully:', user); // 打印日志
            } else {
                console.error('User not found:', username); // 打印错误日志
            }
        },

        clearCurrentUser(state) {
            console.log('Clearing current user'); // 打印日志
            state.currentUser = null;
            localStorage.removeItem('currentUser');
        },
    },
    getters: {
        userPermissions: (state) => {
            if (state.currentUser?.role === "admin") {
                return state.allPermissions; // `admin` 自动拥有最高权限
            }
            return state.currentUser?.permissions || [];
        },
    },
    actions: {
        login({ commit }, username) {
            console.log('Logging in user:', username); // 打印日志
            commit("setCurrentUser", username);
        },
        logout({ commit }) {
            commit("clearCurrentUser");
        },
    },
});
