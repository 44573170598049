import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Manage.vue'
import store from "../store";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Manage',
    component: () => import('../views/Manage.vue'),
    redirect:"/home",
    children:[
      {path:'home',name:'Home',component:() => import('../views/Home.vue'), meta: { title:'淮电智慧云',permissions: [] }},
      {path:'user',name:'User',component:() => import('../views/User.vue'),meta: { requiresAuth: true, permissions: ["user"] }},
      {path:'safeList',name:'SafeList',component:() => import('../views/SafeList.vue'),meta: { requiresAuth: true, permissions: ["safeList"] }},
      {path:'safeStudy',name:'SafeStudy',component:() => import('../views/SafeStudy.vue'),meta: { requiresAuth: true, permissions: ["safeStudy"] }},
      {path:'safeCheck',name:'SafeCheck',component:() => import('../views/SafeCheck.vue'),meta: { requiresAuth: true, permissions: ["safeCheck"] }},
      {path:'safeFind',name:'SafeFind',component:() => import('../views/SafeFind.vue'),meta: { requiresAuth: true, permissions: ["safeFind"] }},
      {path:'video',name:'Video',component:() => import('../views/Video.vue'),meta: { requiresAuth: true, permissions: ["video"] }},
      {path:'wenzhang',name:'WenZhang',component:() => import('../views/WenZhang.vue'),meta: { requiresAuth: true, permissions: ["wenzhang"] }},
      {path:'dept',name:'Dept',component:() => import('../views/Dept.vue'),meta: { requiresAuth: true, permissions: ["dept"] }},
      {path:'position',name:'Position',component:() => import('../views/Position.vue'),meta: { requiresAuth: true, permissions: ["position"] }},
      { path: 'danger', name: 'Danger', component: () => import('../views/Danger.vue') ,meta: { requiresAuth: true, permissions: ["danger"] }},
      {path:'danger_pendingConfirm', name: 'DangerPendingConfirm', component: () => import('../views/Danger/DangerPendingConfirm.vue') ,meta: { requiresAuth: true, permissions: ["danger_pendingConfirm"] }},
      { path: 'danger_deptConfirm', name: 'DangerDeptConfirm', component: () => import('../views/Danger/DangerDeptConfirm.vue') ,meta: { requiresAuth: true, permissions: ["danger_deptConfirm"] }},
      { path: 'danger_pendingProcessing', name: 'DangerPendingProcessing', component: () => import('../views/Danger/DangerPendingProcessing.vue') ,meta: { requiresAuth: true, permissions: ["danger_pendingProcessing"] }},
      { path: 'danger_pendingAcceptance', name: 'DangerPendingAcceptance', component: () => import('../views/Danger/DangerPendingAcceptance.vue'),meta: { requiresAuth: true, permissions: ["danger_pendingAcceptance"] } },
      { path: 'danger_completed', name: 'DangerCompleted', component: () => import('../views/Danger/DangerCompleted.vue') ,meta: { requiresAuth: true, permissions: ["danger_completed"] }},
      { path: 'danger_rejected', name: 'DangerRejected', component: () => import('../views/Danger/DangerRejected.vue') ,meta: { requiresAuth: true, permissions: ["danger_rejected"] }},
      { path: 'rule', name: 'Rule', component: () => import('../views/Rule/Rule.vue') ,meta: { requiresAuth: true, permissions: ["rule"] }},
      { path: 'search', name: 'Search', component: () => import('../views/Rule/Search.vue') ,meta: { requiresAuth: true, permissions: ["search"] }},
      { path: 'ruleEdit', name: 'RuleEdit', component: () => import('../views/Rule/RuleEdit.vue'),meta: { requiresAuth: true, permissions: ["ruleEdit"] } },
      { path: 'ruleListEdit', name: 'RuleListEdit', component: () => import('../views/Rule/RuleListEdit.vue') ,meta: { requiresAuth: true, permissions: ["ruleListEdit"] }},
      { path: 'coalDayUse', name: 'CoalDayUse', component: () => import('../views/Coal/CoalDayUse.vue'),meta: { permissions: ["coalDayUse"] } },
      { path: 'coalYard', name: 'CoalYard', component: () => import('../views/Coal/CoalYard.vue'),meta: { permissions: ["coalYard"] }, },
      { path: 'coalShip', name: 'CoalShip', component: () => import('../views/Coal/CoalShip.vue'),meta: { permissions: ["coalShip"] }, },
      { path: 'coalMixConst', name: 'CoalMixConst', component: () => import('../views/Coal/CoalMixConst.vue'),meta: { permissions: ["coalMixConst"] }, },
      { path: 'coalMixDetail', name: 'CoalMixDetail', component: () => import('../views/Coal/CoalMixDetail.vue'),meta: { permissions: ["coalMixDetail"] }, },
      { path: 'coalMix', name: 'CoalMix', component: () => import('../views/Coal/CoalMix.vue'),meta: { permissions: ["coalMix"] }, },
      { path: 'coalMixDept', name: 'CoalMixDept', component: () => import('../views/Coal/CoalMixDept.vue'),meta: { permissions: ["coalMixDept"] }, },
      { path: 'coalMixDeptFDB', name: 'CoalMixDeptFDB', component: () => import('../views/Coal/CoalMixDeptFDB.vue'),meta: { permissions: ["coalMixDeptFDB"] }, },
      { path: 'coalMixDeptRLB', name: 'CoalMixDeptRLB', component: () => import('../views/Coal/CoalMixDeptRLB.vue'),meta: { permissions: ["coalMixDeptRLB"] }, },
      { path: 'coalMixDeptJXB', name: 'CoalMixDeptJXB', component: () => import('../views/Coal/CoalMixDeptJXB.vue'),meta: { permissions: ["coalMixDeptJXB"] }, },
      { path: 'coalMixDeptWZB', name: 'CoalMixDeptWZB', component: () => import('../views/Coal/CoalMixDeptWZB.vue'),meta: { permissions: ["coalMixDeptWZB"] }, },
      { path: 'coalMixDeptHB', name: 'CoalMixDeptHB', component: () => import('../views/Coal/CoalMixDeptHB.vue'),meta: { permissions: ["coalMixDeptHB"] }, },
      {path:'dangerType',name:'DangerType',component:() => import('../views/DangerType.vue'),meta: { requiresAuth: true, permissions: ["dangerType"] }},
      {path:'order',name:'Order',component:() => import('../views/Order.vue'),meta: { requiresAuth: true, permissions: ["order"] }},
      {path:'advice',name:'Advice',component:() => import('../views/Advice.vue'),meta: { requiresAuth: true, permissions: ["advice"] }},
      {path:'hangup',name:'HangUp',component:() => import('../views/HangUp.vue'),meta: { requiresAuth: true, permissions: ["hangup"] }},
      {path:'powerUser',name:'PowerUser',component:() => import('../views/PowerUser.vue'),meta: { requiresAuth: true, permissions: ["powerUser"] }},
      {path:'powerContract',name:'PowerContract',component:() => import('../views/PowerContract.vue'),meta: { requiresAuth: true, permissions: ["powerContract"] }},
      {path:'powerSearch',name:'PowerSearch',component:() => import('../views/PowerSearch.vue'),meta: { requiresAuth: true, permissions: ["powerSearch"] }},
      {path:'powerSummary',name:'PowerSummary',component:() => import('../views/PowerSummary.vue'),meta: { requiresAuth: true, permissions: ["powerSummary"] }},
      {path:'practice',name:'Practice',component:() => import('../views/Practice.vue'),meta: { requiresAuth: true, permissions: ["practice"] }},
      {path:'coal',name:'Coal',component:() => import('../views/Coal.vue'),meta: { permissions: ["coal"] }},
      {path:'loginLog',name:'LoginLog',component:() => import('../views/LoginLog.vue'),meta: { requiresAuth: true, permissions: ["loginLog"] }},
      {path:'permission',name:'Permission',component:() => import('../views/User/Permission.vue'),meta: { requiresAuth: true, permissions: ["permission"] }},
      {path:'role',name:'Role',component:() => import('../views/User/Role.vue'),meta: {  }},
      {path:'adminUser',name:'AdminUser',component:() => import('../views/User/AdminUser.vue'),meta: {  }},
      {
        path: "/403",
        name: "Forbidden",
        component: () => import("../views/403.vue"),
      },
    ]
  },

  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta:{title:'淮电智慧云'},
    component: () => import('../views/Login.vue')
  },
  {
    path: '/ssp',
    name: 'SSP',
    meta:{title:'随手拍'},
    component: () => import('../views/SSP.vue')
  },
  {
    path: '/exam',
    name: 'Exam',
    meta:{title:'考试'},
    component: () => import('../views/Exam.vue')
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import('../views/Success.vue')
  },
  {
    path: '/dangerTemp',
    name: 'DangerTemp',
    meta:{title:'随手拍'},
    component: () => import('../views/DangerTemp.vue')
  },

]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title; // 设置页面标题
  }

  // 从 localStorage 恢复用户信息
  const savedUser = JSON.parse(localStorage.getItem('currentUser'));
  if (savedUser) {
    store.commit('setCurrentUser', savedUser.username);
  }

  const requiredPermissions = to.meta.permissions || [];
  const userPermissions = store.getters.userPermissions;

  console.log("当前用户权限:", userPermissions);
  console.log("当前用户信息:", store.state.currentUser);

  // 权限检查逻辑
  // if (requiredPermissions.length > 0) {
  //   const hasPermission = requiredPermissions.every(perm => userPermissions.includes(perm));
  //   if (!hasPermission) {
  //     next({ path: '/403' }); // 无权限时跳转到未授权页面
  //     return;
  //   }
  // }

  next();
});


export default router;
